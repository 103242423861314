<template>
  <div>
    <div
      class="align-center text-center mt-6 mx-6"
      v-if="userRole === 'master'"
    >
      <v-card elevation="6" style="margin: auto" max-width="2000">
        <v-toolbar flat dense class="blue darken-3" dark>
          <v-toolbar-title>{{ $t("tables.usersList") }}</v-toolbar-title>
        </v-toolbar>
        <div v-if="$i18n.locale == 'en'" class="pl-4 pr-4 pt-2 pb-2">
          <v-data-table
            :headers="headers"
            :search="userSearch"
            :items="users"
            :items-per-page="5"
            fixed-header
            class="elevation-1"
          >
            <!--TABLE TEMPLATE - CRUD METHODS-->
            <template v-slot:top>
              <v-dialog v-model="dialogUser" max-width="500px">
                <v-card>
                  <v-card-title>
                    <v-icon x-large color="#EF5350">warning</v-icon>
                    <span class="text-h5">{{ formTitleUser }}</span>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-row>
                        <br />
                        <h3>Are you sure you want to delete this user?</h3>
                        <h3>This action cannot be reverted.</h3>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn outlined color="blue darken-1" text @click="close">
                      Cancel
                    </v-btn>
                    <v-btn
                      outlined
                      color="#EF5350"
                      text
                      @click="confirmDeleteUser"
                    >
                      Delete
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <v-icon medium @click="deleteUserItem(item)">delete</v-icon>
            </template>
          </v-data-table>

          <v-row>
            <v-col cols="8"> </v-col>

            <v-col cols="4" class="mt-2 pt-0" style="">
              <v-card-title>
                <v-text-field
                  v-model="userSearch"
                  append-icon="mdi-magnify"
                  :label="$t('tables.search')"
                  single-line
                  hide-details
                  outlined
                ></v-text-field>
              </v-card-title>
            </v-col>
          </v-row>
        </div>

        <div v-if="$i18n.locale == 'pt'" class="pl-4 pr-4 pt-2 pb-2">
          <v-data-table
            :headers="headersPt"
            :search="userSearch"
            :items="users"
            :items-per-page="5"
            fixed-header
            class="elevation-1"
          >
            <!--TABLE TEMPLATE - CRUD METHODS-->
            <template v-slot:top>
              <v-dialog v-model="dialogUser" max-width="500px">
                <v-card>
                  <v-card-title>
                    <v-icon x-large color="#EF5350">warning</v-icon>
                    <span class="text-h5">{{ formTitleUser }}</span>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-row>
                        <br />
                        <h3>
                          Tem a certeza que pretende eliminar o utilizador?
                        </h3>
                        <h3>Não poderá voltar atrás nesta acção.</h3>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn outlined color="blue darken-1" text @click="close">
                      Cancelar
                    </v-btn>
                    <v-btn
                      outlined
                      color="#EF5350"
                      text
                      @click="confirmDeleteUser"
                    >
                      Eliminar
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <v-icon medium @click="deleteUserItem(item)">delete</v-icon>
            </template>
          </v-data-table>

          <v-row>
            <v-col cols="8"> </v-col>

            <v-col cols="4" class="mt-2 pt-0" style="">
              <v-card-title>
                <v-text-field
                  v-model="userSearch"
                  append-icon="mdi-magnify"
                  :label="$t('tables.search')"
                  single-line
                  hide-details
                  outlined
                ></v-text-field>
              </v-card-title>
            </v-col>
          </v-row>
        </div>
      </v-card>
    </div>

    <div
      v-if="userRole === 'master'"
      class="align-center text-center mt-1 mx-6"
    >
      <v-card elevation="6" style="margin: auto" max-width="2000">
        <v-toolbar flat dense class="blue darken-3" dark>
          <v-toolbar-title>{{ $t("tables.addNewUser") }}</v-toolbar-title>
        </v-toolbar>
        <div class="d-flex mt-4 mx-6">
          <v-flex class="md3 mt-3">
            <v-text-field
              v-model="username"
              :label="$t('global.username')"
              autocomplete="off"
              filled
              outlined
              background-color="white"
            ></v-text-field>
          </v-flex>
          <v-flex class="md3 mt-3 ml-6">
            <v-text-field
              v-model="email"
              :label="$t('global.email')"
              autocomplete="off"
              filled
              outlined
              background-color="white"
            ></v-text-field>
          </v-flex>
          <v-flex class="md2 mt-3 ml-6">
            <v-text-field
              v-model="groupId"
              label="groupId"
              autocomplete="off"
              filled
              outlined
              background-color="white"
            ></v-text-field>
          </v-flex>
          <v-flex class="md2 mt-3 ml-6">
            <v-text-field
              v-model="password"
              type="password"
              :label="$t('global.password')"
              autocomplete="off"
              filled
              outlined
              background-color="white"
            ></v-text-field>
          </v-flex>

          <v-flex class="md2 ml-6">
            <v-container fluid>
              <v-combobox
                class=""
                v-model="role"
                :items="items"
                :search-input.sync="roleChosen"
                hide-selected
                hint="Maximum of 1 value"
                :label="$t('global.chooseRole')"
                multiple
                persistent-hint
                small-chips
                outlined
              >
              </v-combobox>
            </v-container>
          </v-flex>

          <br />
          <v-btn class="ml-6 mt-6" elevation="2" @click="createUser()">
            {{ $t("button.create") }}
          </v-btn>
        </div>
        <br />
        <div class="red--text" v-html="error" />
        <div class="green--text" v-html="success" />
      </v-card>
    </div>

    <!-- GROUP LIST -->

    <div
      class="align-center text-center mt-8 mx-6"
      v-if="userRole === 'master'"
    >
      <v-card elevation="6" style="margin: auto" max-width="2000">
        <v-toolbar flat dense class="blue darken-3" dark>
          <v-toolbar-title>{{ $t("tables.groupsList") }}</v-toolbar-title>
        </v-toolbar>
        <div v-if="$i18n.locale == 'en'" class="pl-4 pr-4 pt-2 pb-2">
          <v-data-table
            :headers="groupHeaders"
            :search="groupSearch"
            :items="groups"
            :items-per-page="5"
            fixed-header
            class="elevation-1"
          >
            <!--TABLE TEMPLATE - CRUD METHODS-->
            <template v-slot:top>
              <v-dialog v-model="dialogGroup" max-width="500px">
                <v-card>
                  <v-card-title>
                    <v-icon x-large color="#EF5350">warning</v-icon>
                    <span class="text-h5">{{ formTitleGroup }}</span>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-row>
                        <br />
                        <h3>Are you sure you want to delete this group?</h3>
                        <h3>This action cannot be reverted.</h3>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn outlined color="blue darken-1" text @click="close">
                      Cancel
                    </v-btn>
                    <v-btn
                      outlined
                      color="#EF5350"
                      text
                      @click="confirmDeleteGroup"
                    >
                      Delete
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <v-icon medium @click="deleteGroupItem(item)">delete</v-icon>
            </template>
          </v-data-table>

          <v-row>
            <v-col cols="8"> </v-col>

            <v-col cols="4" class="mt-2 pt-0" style="">
              <v-card-title>
                <v-text-field
                  v-model="groupSearch"
                  append-icon="mdi-magnify"
                  :label="$t('tables.search')"
                  single-line
                  hide-details
                  outlined
                ></v-text-field>
              </v-card-title>
            </v-col>
          </v-row>
        </div>

        <div v-if="$i18n.locale == 'pt'" class="pl-4 pr-4 pt-2 pb-2">
          <v-data-table
            :headers="groupHeadersPt"
            :search="groupSearch"
            :items="groups"
            :items-per-page="5"
            fixed-header
            class="elevation-1"
          >
            <!--TABLE TEMPLATE - CRUD METHODS-->
            <template v-slot:top>
              <v-dialog v-model="dialogGroup" max-width="500px">
                <v-card>
                  <v-card-title>
                    <v-icon x-large color="#EF5350">warning</v-icon>
                    <span class="text-h5">{{ formTitleUser }}</span>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-row>
                        <br />
                        <h3>Tem a certeza que pretende eliminar o Grupo?</h3>
                        <h3>Não poderá voltar atrás nesta acção.</h3>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn outlined color="blue darken-1" text @click="close">
                      Cancelar
                    </v-btn>
                    <v-btn
                      outlined
                      color="#EF5350"
                      text
                      @click="confirmDeleteGroup"
                    >
                      Eliminar
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <v-icon medium @click="deleteGroupItem(item)">delete</v-icon>
            </template>
          </v-data-table>

          <v-row>
            <v-col cols="8"> </v-col>

            <v-col cols="4" class="mt-2 pt-0" style="">
              <v-card-title>
                <v-text-field
                  v-model="groupSearch"
                  append-icon="mdi-magnify"
                  :label="$t('tables.search')"
                  single-line
                  hide-details
                  outlined
                ></v-text-field>
              </v-card-title>
            </v-col>
          </v-row>
        </div>
      </v-card>
    </div>

    <div
      v-if="userRole === 'master'"
      class="align-center text-center mt-1 mx-6 mb-10"
    >
      <v-card elevation="6" style="margin: auto" max-width="2000">
        <v-toolbar flat dense class="blue darken-3" dark>
          <v-toolbar-title>{{ $t("tables.addNewGroup") }}</v-toolbar-title>
        </v-toolbar>
        <div class="d-flex mt-4 mx-12">
          <v-flex class="md6 mt-3">
            <v-text-field
              v-model="groupName"
              :label="$t('global.groupName')"
              autocomplete="off"
              filled
              outlined
              background-color="white"
            ></v-text-field>
          </v-flex>
          <v-flex class="md3 ml-12">
            <v-container fluid>
              <v-combobox
                class=""
                v-model="refPackage"
                :items="packageItems"
                :search-input.sync="packageChosen"
                hide-selected
                hint="Maximum of 1 value"
                label="Choose Package"
                multiple
                persistent-hint
                small-chips
                outlined
              >
              </v-combobox>
            </v-container>
          </v-flex>

          <br />
          <v-btn class="ml-16 mt-6" elevation="2" @click="createGroup()">
            {{ $t("button.create") }}
          </v-btn>
        </div>
        <br />
        <div class="red--text" v-html="groupError" />
        <div class="green--text" v-html="groupSuccess" />
      </v-card>
    </div>
  </div>
</template>

<script>
import UserService from "@/services/UserService";
import GroupService from "@/services/GroupService";
import AppService from "@/services/AppService";
import HelperMethods from "@/utilities/HelperMethods";
import { mapState } from "vuex";

export default {
  data() {
    return {
      userInfo: {},
      users: [],
      groups: [],
      model: ["Vuetify"],
      items: ["Master", "Admin", "User"],
      packageItems: [
        "Individual",
        "Multiple",
        "MINIUS",
        "LIMIA",
        "NERBIS",
        "TURIA",
      ],
      headers: [
        { text: "First Name", value: "firstName" },
        { text: "Last Name", value: "lastName" },
        { text: "Email", value: "email" },
        { text: "Group Name", value: "groupName" },
        { text: "Group Id", value: "refGroup" },
        { text: "Role", value: "role" },
        { text: "Date", value: "createdAt" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      headersPt: [
        { text: "Nome", value: "firstName" },
        { text: "Apelido", value: "lastName" },
        { text: "Email", value: "email" },
        { text: "Grupo", value: "groupName" },
        { text: "Id Grupo", value: "refGroup" },
        { text: "Função", value: "role" },
        { text: "Data", value: "createdAt" },
        { text: "Acções", value: "actions", sortable: false },
      ],
      groupHeaders: [
        { text: "Group Name", value: "name" },
        { text: "Package", value: "packageName" },
        { text: "Date", value: "createdAt" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      groupHeadersPt: [
        { text: "Nome do Grupo", value: "name" },
        { text: "Pacote", value: "packageName" },
        { text: "Data", value: "createdAt" },
        { text: "Acções", value: "actions", sortable: false },
      ],
      username: "",
      email: "",
      password: "",
      role: "",
      groupName: "",
      package: "",
      roleChosen: "",
      packageChosen: "",
      refPackage: null,
      groupId: null,
      userSearch: null,
      groupSearch: null,
      error: null,
      success: null,
      groupError: null,
      groupSuccess: null,

      //v-data-table template variables
      dialogUser: false,
      dialogGroup: false,
      dialogDelete: false,
      editedIndex: -1,
      userToDeleteId: null,
      groupToDeleteId: null,
    };
  },
  computed: {
    ...mapState(["userRole"]),
    formTitleUser() {
      if (this.$i18n.locale == "pt") {
        return this.editedIndex === -1 ? "Nova Entrada" : "Eliminar Utilizador";
      } else return this.editedIndex === -1 ? "New Item" : "Delete User";
    },
    formTitleGroup() {
      if (this.$i18n.locale == "pt") {
        return this.editedIndex === -1 ? "Nova Entrada" : "Eliminar Grupo";
      } else return this.editedIndex === -1 ? "New Item" : "Delete Group";
    },
  },
  methods: {
    async retrieveEntries() {
      let self = this;
      try {
        let response = await UserService.getAllUsers();
        this.users = response.data;

        let response1 = await GroupService.getAllGroups();
        this.groupObj = response1.data;
      } catch (err) {
        console.log(err);
      }
      this.groups = this.groupObj;
      let groups = this.groupObj;

      function nameGroups(groupId) {
        for (let i = 0; i < groups.length; i++) {
          if (groupId === groups[i].id) {
            return groups[i].name;
          }
        }
      }

      function namePackage(packageId) {
        return self.packageItems[packageId - 1];
      }

      this.users.forEach(function (value) {
        value.createdAt = HelperMethods.formatTimestamp(value["createdAt"]);
        value.role = HelperMethods.nameRole(value["refRole"]);
        value.groupName = nameGroups(value["refGroup"]);
      });

      this.groups.forEach(function (value) {
        value.createdAt = HelperMethods.formatTimestamp(value["createdAt"]);
        value.packageName = namePackage(value["refPackage"]);
      });
    },

    async refreshList() {
      await this.retrieveEntries();
    },

    async createUser() {
      try {
        const response = await UserService.masterCreateUser({
          username: this.username,
          email: this.email,
          password: this.password,
          refRole: this.role[0],
          refGroup: this.groupId,
        });
        this.error = null;
        this.success = response.data.message;
        this.refreshList();

        const response1 = await AppService.addUserAction({
          userId: this.userInfo.id,
          userRole: this.userInfo.refRole,
          message: `create user ${this.username} with id: ${response.data.user.id}`,
        });
        console.log("Response: ", response1);
      } catch (error) {
        this.error = error.response.data.error;
      }
    },

    async createGroup() {
      try {
        switch (this.refPackage[0]) {
          case "Individual":
            this.package = 1;
            break;
          case "Multiple":
            this.package = 2;
            break;
          case "MINIUS":
            this.package = 3;
            break;
          case "LIMIA":
            this.package = 4;
            break;
          case "NERBIS":
            this.package = 5;
            break;
          case "TURIA":
            this.package = 6;
            break;
          default:
            this.package = null;
            break;
        }

        const response = await GroupService.createGroup({
          name: this.groupName,
          refPackage: this.package,
        });
        this.groupError = null;
        this.groupSuccess = "Group successfully created.";
        this.refreshList();
        console.log("Response: ", response);

        const response1 = await AppService.addUserAction({
          userId: this.userInfo.id,
          userRole: this.userInfo.refRole,
          message: `create group ${this.groupName} with id: ${response.data.group.id} & package: ${response.data.group.refPackage}`,
        });
        console.log("Response: ", response1);
      } catch (err) {
        this.groupError = "Group creation failed.";
        console.log(err);
      }
    },

    async deleteUser(id) {
      UserService.masterDelete(id)
        .then(() => {
          this.error = null;
          this.success = "User successfully removed.";
          this.refreshList();
          AppService.addUserAction({
            userId: this.userInfo.id,
            userRole: this.userInfo.refRole,
            message: `delete user id:  ${id}`,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async deleteGroup(id) {
      GroupService.delete(id)
        .then(() => {
          this.groupError = null;
          this.groupSuccess = "Group successfully removed.";
          this.refreshList();
          AppService.addUserAction({
            userId: this.userInfo.id,
            userRole: this.userInfo.refRole,
            message: `delete group id:  ${id}`,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },

    deleteUserItem(item) {
      this.editedIndex = this.users.indexOf(item);
      this.userToDeleteId = item.id;
      this.dialogUser = true;
    },

    confirmDeleteUser() {
      this.deleteUser(this.userToDeleteId);
      this.close();
    },

    deleteGroupItem(item) {
      this.editedIndex = this.groups.indexOf(item);
      this.groupToDeleteId = item.id;
      this.dialogGroup = true;
    },

    confirmDeleteGroup() {
      this.deleteGroup(this.groupToDeleteId);
      this.close();
    },

    close() {
      this.dialogUser = false;
      this.dialogGroup = false;
      this.$nextTick(() => {
        this.editedIndex = -1;
      });
    },
  },
  async mounted() {
    this.userInfo = this.$store.getters.userInfo;
    await this.retrieveEntries();
  },
  watch: {
    //Combobox v-model value is being watched to prevent the selection of more than 1 value
    role(val) {
      if (val.length > 1) {
        this.$nextTick(() => this.role.pop());
      }
    },
    refPackage(val) {
      if (val.length > 1) {
        this.$nextTick(() => this.refPackage.pop());
      }
    },
  },
};
</script>

<style scoped></style>
