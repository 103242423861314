import Api from "@/services/Api";

export default {
  addUserAction(info) {
    return Api().post("addUserAction", info);
  },
  getUserEntries(info) {
    return Api().get("getUserEntries", info);
  },
  getLogEntriesByPeriodOfTime(dateFrom, dateTo) {
    return Api().get("getLogEntriesByPeriodOfTime", {
      params: {
        dateFrom,
        dateTo,
      }
    });
  },
};
